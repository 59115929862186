// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax,import/extensions
import 'default-passive-events';
import $ from 'jquery';
import Backbone from 'backbone';
import './lib/browserUpdate';
import './lib/lazyLoad';
import './lib/progressive';

import '../../sass/2011/heischehoeve.scss';

// fix for downstream libraries in ie8/ie9 etc
if (!window.console) {
  const noop = () => {};
  window.console = {
    log: noop,
    info: noop,
    warn: noop,
    error: noop,
    trace: noop
  };
}
if (!window.console.info) {
  window.console.info = window.console.log;
}

// 'jqueryui/ui/core': '../bower_components/jquery-ui/ui/core',
// 'jqueryui/ui/datepicker': '../bower_components/jquery-ui/ui/datepicker',
// 'jqueryui/ui/i18n/datepicker-nl': '../bower_components/jquery-ui/ui/i18n/datepicker-nl',
// 'handlebars': '../bower_components/handlebars/handlebars.amd',
// 'select2-core': '../bower_components/select2/dist/js/select2',
// 'select2': '../bower_components/select2/dist/js/i18n/nl',

// eslint-disable-next-line import/no-dynamic-require
require([
  './l10n/nl',
  './page/main',
  './page/catalog',
  './page/satisfaction',
  './widget/brochureRequestForm',
  './widget/boxCarousel/widget',
], () => {
  // init app
  $(document).on('ready', () => {
  });

  // lazy load images
  $('img[data-src],[data-background-image-url]').lazyLoad();

  // Googlebot does this twice? --- prevent superfluous error reporting...
  if (!Backbone.History.started) {
    Backbone.history.start();
  }
});
